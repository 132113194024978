import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUserEmail } from '../../../selectors/current-site-user';
import {
  canShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  canShowChannelTitle,
  isMenuPreview,
  isActionBarPreview,
  isRTL,
} from '../../../selectors/app-settings';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import {
  canShowCancelSubscription,
  canShowSubscription,
} from '../../../selectors/subscription';
import { logOutCurrentMember } from '../../../utils/auth';
import EVENTS from '../../../constants/events';

import MenuItem from './menu-item/menu-item';
import ChannelSubscriptionLabel from '../../channel-subscription-label/channel-subscription-label';
import Popover from '@wix/wix-vod-shared/dist/src/widget/ui-components/popover/popover';
import Title from './title/title';
import MenuIcon from './icons/menu.svg';
import SignInIcon from './icons/sign-in.svg';
import styles from './menu.scss';

import { withTranslation } from '@wix/yoshi-flow-editor';
import { logBi } from '../../../worker/actions/bi';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from '../../../containers/pub-sub-events';

const mapStateToProps = (state) => ({
  isMenuOpen: isMenuPreview(state) || isActionBarPreview(state),
  currentSiteUserEmail: getCurrentUserEmail(state),
  canShowChannelTitle: canShowChannelTitle(state),
  canShowSignIn: getCanShowSignIn(state),
  canShowChannelInfo: getCanShowChannelInfo(state),
  canShowChannelShare: canShowChannelShare(state),
  canShowSubscription: canShowSubscription(state),
  canShowCancelSubscription: canShowCancelSubscription(state),
  isRTL: isRTL(state),
});

const mapDispatchToProps = { logBi, logOutCurrentMember };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withPubSubEvents(
      class Menu extends React.Component {
        static propTypes = {
          canShowChannelTitle: PropTypes.bool.isRequired,
          canShowSignIn: PropTypes.bool.isRequired,
          canShowChannelInfo: PropTypes.bool.isRequired,
          canShowChannelShare: PropTypes.bool.isRequired,
          canShowSubscription: PropTypes.bool.isRequired,
          canShowCancelSubscription: PropTypes.bool.isRequired,
          currentSiteUserEmail: PropTypes.string.isRequired,
          isMenuOpen: PropTypes.bool.isRequired,
          isRTL: PropTypes.bool,

          channel: PropTypes.object.isRequired,
          onInfoRequest: PropTypes.func.isRequired,
          onShareRequest: PropTypes.func.isRequired,
          onSubscriptionRequest: PropTypes.func.isRequired,
          onCancelSubscriptionRequest: PropTypes.func.isRequired,
          onLogInRequest: PropTypes.func.isRequired,
          onShowAccountInfoRequest: PropTypes.func.isRequired,
        };

        componentDidUpdate(prevProps) {
          consumePubSubEvent(
            EVENTS.SESSION.LOGOUT_USER,
            this.handleLogoutEvent,
            {
              prevProps,
              nextProps: this.props,
            },
          );
        }

        componentWillUnmount() {
          this.unsubscribeLogoutEvent();
        }

        handleLogoutEvent = () => {
          this.props.logOutCurrentMember();
          this.unsubscribeLogoutEvent();
        };

        handlePopoverToggle = (isOpen) => {
          if (isOpen) {
            this.logMenuClickedBi();
          }
        };

        logMenuClickedBi = () => {
          const { channel } = this.props;
          this.props.logBi('widget.menu.clicked', { channelID: channel.id });
        };

        handleSubscribeRequest = () => {
          const { onSubscriptionRequest } = this.props;
          onSubscriptionRequest();
        };

        handleChannelInfoRequest = () => {
          const { onInfoRequest, channel } = this.props;
          this.props.logBi('widget.aboutCh.clicked', { channelID: channel.id });
          onInfoRequest();
        };

        renderSubscribe() {
          const { channel } = this.props;

          return (
            <MenuItem
              onClick={this.handleSubscribeRequest}
              key="channel-subscription"
              dataHook="channel-subscription-button"
            >
              <ChannelSubscriptionLabel dealInfo={channel.dealInfo} />
            </MenuItem>
          );
        }

        renderSubscriptionCancel() {
          const { onCancelSubscriptionRequest } = this.props;

          return (
            <MenuItem
              onClick={onCancelSubscriptionRequest}
              key="channel-subscription-cancel"
              dataHook="channel-subscription-cancel"
            >
              {this.props.t('widget.payments.cancel-subscription')}
            </MenuItem>
          );
        }

        renderShare() {
          return (
            <MenuItem
              onClick={this.props.onShareRequest}
              key="channel-share"
              dataHook="share-overlay-button"
            >
              {this.props.t('widget.accessibility.share')}
            </MenuItem>
          );
        }

        renderSignIn(hasOtherItems) {
          const {
            currentSiteUserEmail,
            onShowAccountInfoRequest,
            onLogInRequest,
            channel,
          } = this.props;

          const className = classnames({
            [styles.withSeparator]: hasOtherItems,
          });

          if (currentSiteUserEmail) {
            const onClick = isPricingPlanEnabled(channel)
              ? undefined
              : onShowAccountInfoRequest;
            return (
              <MenuItem
                onClick={onClick}
                className={className}
                key="channel-account-info"
                icon={<SignInIcon className={styles.signIn} />}
                dataHook="channel-account-info"
              >
                {currentSiteUserEmail}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              onClick={onLogInRequest}
              className={className}
              key="channel-login"
              dataHook="login-button"
            >
              {this.props.t('widget.account.sign-in')}
            </MenuItem>
          );
        }

        renderChannelInfo() {
          return (
            <MenuItem
              onClick={this.handleChannelInfoRequest}
              key="channel-info"
              dataHook="channel-info-button"
            >
              {this.props.t(
                'widget.compact-view.components.action-bar.channel-info-tooltip',
              )}
            </MenuItem>
          );
        }

        renderContent() {
          const {
            canShowSignIn,
            canShowChannelInfo,
            canShowChannelShare,
            canShowSubscription,
            canShowCancelSubscription,
          } = this.props;

          const restItems = _.compact([
            canShowSubscription && this.renderSubscribe(),
            canShowCancelSubscription && this.renderSubscriptionCancel(),
            canShowChannelShare && this.renderShare(),
            canShowChannelInfo && this.renderChannelInfo(),
          ]);

          const content = _.compact([
            canShowSignIn && this.renderSignIn(restItems.length),
            ...restItems,
          ]);

          return _.size(content) ? (
            <div className={styles.popoverMenuContainer}>{content}</div>
          ) : null;
        }

        renderTitle() {
          const { canShowChannelTitle, channel } = this.props;

          return canShowChannelTitle ? (
            <div className={styles.title}>
              <Title dataHook="channel-title">{channel.title}</Title>
            </div>
          ) : null;
        }

        renderMenuWithTitle(content) {
          const { channel, isRTL } = this.props;

          return (
            <Popover
              ariaRole="combobox"
              openerClassName={styles.menuContainer}
              ariaLabel={channel.title}
              isOpen={this.props.isMenuOpen}
              className={styles.popoverMenu}
              content={content}
              onToggle={this.handlePopoverToggle}
              isRTL={isRTL}
              dropdownPosition={isRTL ? 'right' : 'left'}
            >
              <div data-hook="menu-button" className={styles.menu}>
                <MenuIcon className={styles.menuButton} />
                {this.renderTitle()}
              </div>
            </Popover>
          );
        }

        render() {
          const content = this.renderContent();

          return (
            <div className={styles.container} data-hook="channel-actions">
              {content ? this.renderMenuWithTitle(content) : this.renderTitle()}
            </div>
          );
        }
      },
    ),
  ),
);
