import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES, UpdateLiveStatusPayload } from '../actions/videos';
import {
  GetPublicChannelAction,
  NAMES as CHANNEL_GET,
} from '../actions/channel/get-public';
import {
  ListPublicChannelVideosAction,
  NAMES as CHANNEL_VIDEOS_LIST,
} from '../actions/channel/videos/list-public';
import { Video } from '../types';
import {
  CHANNEL_PAYMENT_ACTIONS,
  GetPaymentInfoAction,
} from '../actions/channel/payment';
import {
  GET_PUBLIC_VIDEO_ACTIONS,
  GetPublicVideoAction,
} from '../actions/channel/video/get';

export type VideosState = Record<string, Video>;

export default handleActions<VideosState, any>(
  {
    [NAMES.UPDATE_VIDEO_LIVE_STATUS]: (
      state,
      { payload }: { payload: UpdateLiveStatusPayload },
    ) => ({
      ...state,
      [payload.id]: payload,
    }),
    [CHANNEL_VIDEOS_LIST.SUCCESS]: (
      state,
      { payload }: ListPublicChannelVideosAction,
    ) => {
      const videos = payload.response?.data.entities.videos;
      return {
        ...state,
        ...videos,
      };
    },
    [CHANNEL_GET.SUCCESS]: (state, { payload }: GetPublicChannelAction) => {
      const videos = payload.response?.data.entities.videos; // featured video
      if (videos) {
        return _.merge({}, state, videos);
      }
      return state;
    },
    [CHANNEL_PAYMENT_ACTIONS.NAMES.SUCCESS]: (
      state,
      { payload }: GetPaymentInfoAction,
    ) => {
      const videos = payload.response?.videos;
      if (videos) {
        const newState = {
          ...state,
        };

        for (const video of videos) {
          if (video.itemId) {
            newState[video.itemId] = {
              ...newState[video.itemId],
              ...video,
            };
          }
        }

        return newState;
      }
      return state;
    },
    [GET_PUBLIC_VIDEO_ACTIONS.SUCCESS]: (
      state,
      { payload }: GetPublicVideoAction,
    ) => {
      const video = payload.response;

      if (video) {
        return _.merge({}, state, { [video.id]: video });
      }
      return state;
    },
  },
  {},
);
